.app {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_container {
  width: 24rem;
  height: 27rem;
  border-radius: 10px;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.app_container .img {
    width: 24rem;
    height: 20rem;
}

.app_container .descrip {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}

.app_container a {
  text-decoration: none;
  color: #000;
}

.app_container .card_btn {
  width: 8rem;
  height: 3rem;
  background-color: #0483eb;
  color: #000;
  outline: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
  margin-top: 7px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_container .card_btn:hover {
  letter-spacing: 1px;
  opacity: 0.7;
}
